/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@include mat.core();

@import "src/themes/generated/variables.base";


// $primary-palette: (
//     50: #e0f8ee,
//     100: #b2edd5,
//     200: #7ae1ba,
//     300: #06D6A0,
//     400: #00cc89,
//     500: #00c074,
//     600: #00b169,
//     700: #009e5b,
//     800: #008c4e,
//     900: #006c37,
//     contrast: (
//         50: rgba(black, 0.87),
//         100: rgba(black, 0.87),
//         200: rgba(black, 0.87),
//         300: white,
//         // ... continues to 900
//     )
// );

$primary-palette: (
    50: lighten($cor-primaria, 40%),
    100: lighten($cor-primaria, 30%),
    200: lighten($cor-primaria, 20%),
    300: lighten($cor-primaria, 10%),
    400: $cor-primaria,
    500: darken($cor-primaria, 10%),
    600: darken($cor-primaria, 20%),
    700: darken($cor-primaria, 30%),
    800: darken($cor-primaria, 40%),
    900: darken($cor-primaria, 50%),
    contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
    )
);

$secondary-palette: (
    50: lighten($cor-secundaria, 40%),
    100: lighten($cor-secundaria, 30%),
    200: lighten($cor-secundaria, 20%),
    300: lighten($cor-secundaria, 10%),
    400: $cor-secundaria,
    500: darken($cor-secundaria, 10%),
    600: darken($cor-secundaria, 20%),
    700: darken($cor-secundaria, 30%),
    800: darken($cor-secundaria, 40%),
    900: darken($cor-secundaria, 50%),
    contrast: (50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
    )
);

// Define a light theme
$light-primary: mat.define-palette($primary-palette);
$light-accent: mat.define-palette($secondary-palette);
$light-warn: mat.define-palette(mat.$red-palette);

$custom-theme: mat.define-light-theme((color: (primary: $light-primary,
                accent: $light-accent,
                warn: $light-warn )));



@include mat.core-color($custom-theme);
@include mat.button-color($custom-theme);
// @include mat.circle($custom-theme);
@include mat.core($custom-theme);



@font-face {
    font-family: 'abeatbyKai';
    src: url('assets/fonts/AbeatbyKai.woff2') format('woff2');
}


html,
body {
    height: 100%;
}

body {
    margin: 0;
}

html, body, h1, h2, h3, h4, h5, h6, p, small, div, section, label, li, ul, ol, u, i, strong, b, a, span {
    font-family: abeatbyKai, Roboto, "Helvetica Neue", sans-serif !important;
}


/* Estilo para a barra de rolagem */
::-webkit-scrollbar {
    width: 6px;
}

/* Estilo para o indicador de rolagem (thumb) */
::-webkit-scrollbar-thumb {
    background-color: rgba(112, 112, 112, 0.3);
    /* cor do indicador */
    border-radius: 3px;
    /* arredondamento do indicador */
}


.error-snackbar {
    background-color: rgb(220, 96, 96);
    color: white;
}

.success-snackbar {
    background-color: rgb(62, 151, 62);
    color: white;
}


button:focus {
    outline: none;
}

.small-shadow {
    box-shadow: 0px 0px 5px 1px #ededed;
}

.circle {
    border-radius: 50%;
    display: inline-block;
}

.disable-tooltip {

    [matTooltip],
    *[mattooltipclass="tooltip-celula-body-tabela"] {
        pointer-events: none;
    }
}